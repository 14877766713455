<template>
  <div class="d-flex flex-row">
    <picker
      class="ma-2"
      :selected-id.sync="selectedLabelId"
      :selected-document.sync="selectedLabel"
      document-types="labels"
    />
    <picker
      class="ma-2"
      :selected-id.sync="selectedCurrencyId"
      :selected-document.sync="selectedCurrency"
      document-types="currencies"
    />
    <picker
      class="ma-2"
      :selected-id.sync="selectedCultureId"
      :selected-document.sync="selectedCulture"
      document-types="cultures"
    />
  </div>
</template>
<script>
export default {
  components: {
    Picker: () => import('@/components/picker')
  },
  data () {
    return {
      selectedLabelId: null,
      selectedLabel: null,
      selectedCulture: null,
      selectedCultureId: null,
      selectedCurrencyId: null,
      selectedCurrency: null
    }
  }

}
</script>
